import React, {useEffect} from "react";
import {Box, Grid} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import useAxios from "axios-hooks";
import {WordcloudComponent} from "../components/WordcloudComponent";
import {useLocation} from "react-router-dom";
import {useQuery} from "../custom-hooks/useQuery";

export const WordCloud = () => {
    const query = useQuery()

    return(
        <Grid container sx={{width:'100%', height:'100vh'}}>
            <Grid item sx={{width:'100%', height:'100%'}} xs={query.get("brand")==="doppia" ? 6 : 12}>
                <WordcloudComponent numero={query.get("numero")} brand={query.get("brand")==="doppia" ? 'moncler' : query.get("brand")}/>
            </Grid>
            {query.get("brand") === "doppia" && <Grid item sx={{width: '100%', height: '100%'}} xs={6}>
                <WordcloudComponent numero={query.get("numero")}  brand={'stone-island'}/>
            </Grid>}
        </Grid>
    )
}
