import {Box, Fade, Grow, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";

export const Domanda = ({numero}) => {
    const {brand} = useParams()
    const [testo, loading, error] = useDocument(firestore.doc(`domande/${numero}`), {})

    return (
        <Box>
            <Box sx={{width: '6rem', margin: 'auto'}}>
                <img style={{width: '100%'}} src={require(`../../assets/loghi/white/${brand}.png`)}/>
            </Box>
            <Box sx={{minHeight:'4rem'}}>
                {(!loading && !error) &&
                        <Fade in={true} timeout={800} exit={true}>
                            <Typography variant={"h4"} fontWeight={'bold'}>
                                {testo.data().testo}
                            </Typography>
                        </Fade>
                }
            </Box>
        </Box>
    )
}
