import logo from './logo.png';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {CssBaseline} from "@mui/material";
import {Vote} from "./pages/Vote";
import {WordCloud} from "./pages/WordCloud";

function App() {
    return (
        <div className="App">
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/vote/:brand'} element={<Vote/>}/>
                <Route path={'/wordcloud'} element={<WordCloud/>}/>
            </Routes>
        </div>
    );
}

export default App;
