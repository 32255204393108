import {createTheme, responsiveFontSizes} from "@mui/material";

export const monclerTheme = responsiveFontSizes(createTheme(({
    components:{
      MuiButton:{
          styleOverrides:{
              root:{
                  borderRadius:0
              }
          }
      }
    },
    palette:{
        mode:'dark',
        primary:{
            main:'#fff'
        }
    },
})))
