import React, {useEffect} from "react";
import useAxios from "axios-hooks";
import {Box} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import {API_URL} from "../config";

const options = {
    colors: ['#fff'],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "MonclerGothic",
    fontSizes: [22, 85],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 3,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1500
};

export const WordcloudComponent = ({brand,numero}) => {
    const [{data, loading, error}, refetch ]= useAxios(`${API_URL}wordcloud/${brand}/${numero}`)

    useEffect(() => {
        setInterval(() => {
            refetch()
        }, 3000)
        //return () => clearInterval(interval);
    },[])

    const callbacks = {
        getWordColor: word => (word.text === data[0].text
            //|| (word.text === data[1].text && brand==='moncler')
        )
            ?
            '#ffff00' /*'#fff'*/ : '#fff'
    }

    return (
        <Box style={{width: '100%', height: '100%'}}>
            {(data && !error) && <div style={{width: '100%', height: '100%'}}>
                <ReactWordcloud
                    maxWords={40}
                    //callbacks={callbacks}
                    options={options}
                    words={data}/>
            </div>}
        </Box>
    )
}
