import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Fade,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Domanda} from "../components/Vote/Domanda";
import {mockData} from "../data/mockData";
import {Link, useParams} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import axios from "axios";
import {API_URL} from "../config";
import MuiAlert from '@mui/material/Alert';
import useAxios from "axios-hooks";
import {green} from "@mui/material/colors";
import {useLocalStorage} from "../custom-hooks/useLocalStorage";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Vote = () => {
    const {brand} = useParams()
    const [domanda, loading, error] = useDocument(firestore.doc('config/domanda_attiva'), {})
    const [value, setValue] = React.useState(null);
    const [sending, setSending] = useState(false);
    const [votato, setVotato] = useLocalStorage('votato', 'false');
    const [{data: partecipanti, loading: loadingDipendenti, error: errorDipendenti }, refetch] = useAxios(`${API_URL}dipendenti/${brand}`)

    useEffect(() => {
        if(!loading && !error) {
            if(localStorage.getItem("ultimaDomanda") !== domanda.data().value.toString()){
                setVotato('false')
            }
            localStorage.setItem("ultimaDomanda", domanda.data().value)
        }
    },[domanda])

    const sendData = async () => {
        setSending(true)
        await axios.post(`${API_URL}vote/${brand}/${domanda.data().value}`, value)
            .then(r => setValue(null))
            .finally(() => {
                setVotato('true')
            })
            .catch(e => console.log(e))
        setSending(false)
    }

    return (
        <Container maxWidth={'xs'} sx={{height: '100%', position: 'relative'}}>
            <Button sx={{position: 'absolute', left: '10px', top: '10px'}} component={Link} to={'/'}
                    variant={"outlined"} size={'small'} startIcon={<ArrowBackIcon/>}>indietro</Button>
            {(loading || loadingDipendenti) ?
                <Stack sx={{height: '100%'}} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress/>
                </Stack>
                :
                <Stack spacing={20} sx={{pt: 8, height: '100%'}} justifyContent={"start"}>
                    <Domanda numero={domanda.data().value}/>
                    {votato==='true' ? <Box>
                        <Typography variant={"h5"} fontWeight={"bold"} color={green[400]}>Voto inviato</Typography>
                        <Typography variant={"h6"}>Attendi la prossima domanda</Typography>
                    </Box> :  domanda.data().value !==0 && <Fade in={true} timeout={800} exit={true} style={{transitionDelay: `200ms`}}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            value={value}
                            disablePortal
                            id="combo-box-demo"
                            options={partecipanti}
                            sx={{width: "100%"}}
                            renderInput={(params) => <TextField {...params} label="Scegli un collega"/>}
                        />
                    </Fade>}
                    {(votato!=='true' && domanda.data().value!==0) && <Fade in={true} timeout={800} exit={true} style={{transitionDelay: `400ms`}}>
                        <Box sx={{minHeight: '2.5rem'}}>
                            {sending ? <CircularProgress/> : <Button disabled={value===null} sx={{fontSize:'1.7rem', fontWeight:'bold'}} variant={"contained"} onClick={sendData}>
                                Vota
                            </Button>}
                        </Box>
                    </Fade>}
                </Stack>}
        </Container>
    )
}
