import React, {useState} from "react";
import {Autocomplete, Box, Button, Container, Divider, Fade, Grow, Stack, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const Home = () => {

    return (
        <Container maxWidth={'xs'} sx={{height: '100%'}}>
            <Stack spacing={5} sx={{height: '100%', pt: 5}} alignItems={"center"} justifyContent={"start"}>
                <Typography variant={"h5"} fontWeight={"bold"} textTransform={"uppercase"}>
                    Seleziona il tuo brand
                </Typography>
                <Stack spacing={3}>
                    <Fade in={true} timeout={800}>
                        <Stack sx={{width: '100%'}}>
                            <Box sx={{width: 'min(100%, 20rem)', margin: 'auto', px: 5, py: 2}}>
                                <img style={{width: '100%'}}
                                     src={require('../assets/loghi/white/moncler.png')}/>
                            </Box>
                            <Box>
                                <Button component={Link} to={'/vote/moncler'} variant={"contained"}>Seleziona</Button>
                            </Box>
                        </Stack>
                    </Fade>
                    <Box sx={{
                        borderBottom: '1px solid #fff',
                        pt: 2,
                        width: '100%',
                        margin: 'auto',
                        opacity: '.5'
                    }}></Box>
                    <Fade in={true} timeout={800} style={{transitionDelay: `200ms`}}>
                        <Stack sx={{width: '100%'}}>
                            <Box sx={{width: 'min(100%, 20rem)', margin: 'auto', px: 5, py: 2}}>
                                <img style={{width: '100%'}} src={require('../assets/loghi/white/stone-island.png')}/>
                            </Box>
                            <Box>
                                <Button component={Link} to={'/vote/stone-island'}
                                        variant={"contained"}>seleziona</Button>
                            </Box>
                        </Stack>
                    </Fade>
                </Stack>
            </Stack>
        </Container>
    )
}
